import "../../component/src/components/affine-other-page-layout/index.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/components/affine-other-page-layout/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81UzY7aMBC+8xSjlSrBwShhYbv1Xvom1SSeBENiW/YA2Va8exWHND8LrdpTT4TxzPj7Gc/6W7pLw+GQJvBjAbAnXe5ZQpokn94WABeteD/8VTq4Ct8lFBU1baD9FUp7yllbIyG31ak28cQaFkF/Jwln9EshsCi0IRHjGQZatVnOBt1VeqqQ9ZnaaIb5sfT2ZNSseDgQzusa/bvIbWX96m1xXax7KmmkEg8kaLMnr3mSsIkJbJ2EpL2vooJvn77jn9xlO6AtdEOqDWGlSyM0Ux0k5GSYfBs+nALrokVnmAxLCA5zEhnxhSjq41ApbUoJ6YtrIN0krplgfJ74sdu45o4fdwCJC2VHzQKdE57KeKg8lpPm29j8A7/OPVGik7Cd4dmNRZ2YwtTwBzv+MAChHnIuPcckqh7bKcqtx46asYYmim1bwV5n+F4ivtGlm627maZbVwSdyXCQgCe208q1q1Cbv+M3qv8SKwdnzpe36Us67yfwk38dcYDMekVeeFT6FPpOMTbIlNlGhD0qe+ljI6z4/7iYblwD3dgD1NiIh7M9WQ13FGCPps8d5INk/RyAMMwUkFgw+ZsOt9f59DS9EbNgqxPfbmS29XwlZJXNj6MXmWOVL1voIGCbuGY1noFkvetoPna9wvwo0mQ1w2osL2WFgYUtBL87WkXgNfpSGzGCdl18rUlphJB7IgNoFCwnqrbvoaue7cH5Luo9uY5Tt7fUXwL0fk6yXh9n3QeozW8Bfn7U75FZ18X1J1lblofSBgAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var affineLogo = '_151sjj11';
export var draggableHeader = '_151sjj13';
export var hideInSmallScreen = '_151sjj18';
export var hideInWideScreen = '_151sjj17';
export var iconButton = '_151sjj16';
export var menu = '_151sjj19';
export var menuItem = '_151sjj1a';
export var root = '_151sjj10';
export var topNav = '_151sjj12';
export var topNavLink = '_151sjj15';
export var topNavLinks = '_151sjj14';