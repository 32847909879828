import "../../core/src/components/tags/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/tags/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VW247aMBB95yumK1WCbr1KWPZm1H5HpYoH40wSt45tOQ4krfrvlR1IAgkrqNQHQIzHM+ecuSQPjciLiEffE+YYwcK45ouzFW7g9wyAa6kthR2zc0JYmgqFxEjGMdcyQUvC+WI9+zN7aOPE4VoiSiNZQyGVWK9nEH5JIixyJ7SiPm5VKH+yF4nLKcRR9NH/zZihEC9NPYi5DDHDyTI6OXmczhZcn02feW+9xX+PUnaxViGWYUkiVEYh8p5Gl6IFbFEyJ3borU4bCpxJPiePD0+mhvuDQJYloiZGG4OWMMVzbUmOIsvdAj4BiRf+tsTUUWhvDtCEeJfDBK8F3EP8bOoQR+/QplLvKeQiSVANqDy9I8vqoiwd9Tgy9aEGAFvGf2ZWVyohU82QexhdGwAUQh0oU1idl/EE3lZb30GebFXSa/PtlkSyBi0Z+BkrCmabYRs+hxSBZGa9RvGYYahwwWwmFAXSm1pgFJRWody6clIo7A2pVo6U4hdSiI9yelPKCiEbCkLlaIVb9/MzsIz5OctUaZhF5YYMKB0M2r8N48utwxg65LWl1E+Ctxw/rWZ1X+Moao19O7LK6amyv4wGrPN5nQb6oyqdSBvCtXKoHIXSMI5ki26PGAAzKTJFhMOipMBRObRj7Gc1O9Ev2Oty0fnsD8Seomh9QXOHtSMlcq0SZpux7B3jlta7qctiePMtXDhKSep+ti/tomEtlsda9GUcY3r7b8N3yBudFGB1goJd15BW728q7lGAx9U5fwBe2dITMVocA5yRP4PYPghTzasSk8Gj8KZFONa9pd6NzWq6OmfZ73z6u83E1esXybbtKcO4cM1h7Kgo58eMNCD/fESwXMD0xXgYs4VZOubwizaoNpd9+cQq7g6T050QlvA1ExvG5qbpPO+SsfbJexVKw2FQONW2OIgtmcNv81V4Ineu2a1bd3KGL+7TrNW+fUkoudVSEmaRkZ3AvdHWbeArJGJ3isKxrUT4IArvwUKD/AV15tCL+gkAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var inlineTagsContainer = 'yihm0c3';
export var menuItemList = 'yihm0cg';
export var menuItemListScrollable = 'yihm0ce';
export var menuItemListScrollbar = 'yihm0cf';
export var searchInput = 'yihm0c6';
export var spacer = 'yihm0cc';
export var tagEditIcon = 'yihm0cb';
export var tagSelectorEmpty = 'yihm0cd';
export var tagSelectorItem = 'yihm0ca';
export var tagSelectorTagsScrollContainer = 'yihm0c9';
export var tagsEditorRoot = 'yihm0c1';
export var tagsEditorRootMobile = 'yihm0c2 yihm0c1';
export var tagsEditorSelectedTags = 'yihm0c5';
export var tagsEditorTagsSelector = 'yihm0c7';
export var tagsEditorTagsSelectorHeader = 'yihm0c8';
export var tagsInlineEditor = 'yihm0c0';
export var tagsMenu = 'yihm0c4';