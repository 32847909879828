import "../../core/src/modules/open-in-app/views/open-in-app-page.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/modules/open-in-app/views/open-in-app-page.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA62T3VKDMBCF7/sUubQXcaDiX3wYJ8ACq2HDJNuCOr67k1AVsLV1xiuSZbN7vpPN5WNaE+RsEvG2EqIBrBtWIk2SXfOwEqLHkptx34d9ib4z+kWJysAQAk9bz1i9yMISA7ESBRCDC7+0wZokMrR+Gq4ssfT4CkrstLuQUlcVEsgYz7WHdcjqrEdGS0o4MJpxBw+r99Xlp9w0yi2ssU4JpAYc8ixhExO+q+jcW7NlCLXZdkokYWWg4v3SjeTJQcojKD/gfacLkDlwD0ARQ5clUq1EetMNIt0k3TCTeRVl/uhXWLNtSda6UyJbHMmm6DMHGQaWncNWu6DJWLc+abhvv3P6/e1fJ9GFWK6Ewjo9ekiWYAaVBaa7hb7r00jBjNmZm6NMBul5yXKuztPgEwm3h2WHryzRQTHWHjF+mYlWuxpJxhnLltd9F5tMU8aJ+Irlltm2SqSbxcn7ePJP+Ofaea5D+p8ccrbfj8FmZD/8Sj+tyJYDlh/WcaTb2Gnv8q+8g18fsy167KGwVE7f1vsH4GCAOj8FAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var affineLogo = '_1gnebtl1';
export var centerContent = '_1gnebtl7';
export var docFooter = '_1gnebtla';
export var docFooterText = '_1gnebtlb';
export var editSettingsLink = '_1gnebtl9';
export var prompt = '_1gnebtl8';
export var promptLink = '_1gnebtl6';
export var promptLinks = '_1gnebtl5';
export var root = '_1gnebtl0';
export var topNav = '_1gnebtl2';
export var topNavLink = '_1gnebtl4';
export var topNavLinks = '_1gnebtl3';