import { ConfigModal } from '@affine/core/components/mobile';
import { AuthService } from '@affine/core/modules/cloud';
import type {
  DialogComponentProps,
  GLOBAL_DIALOG_SCHEMA,
} from '@affine/core/modules/dialogs';
import { useI18n } from '@affine/i18n';
import { useService } from '@toeverything/infra';
import { useEffect } from 'react';

import { AboutGroup } from './about';
import { AppearanceGroup } from './appearance';
import { OthersGroup } from './others';
import * as styles from './style.css';
import { UserProfile } from './user-profile';
import { UserUsage } from './user-usage';

const MobileSetting = () => {
  const session = useService(AuthService).session;
  useEffect(() => session.revalidate(), [session]);

  return (
    <div className={styles.root}>
      <UserProfile />
      <UserUsage />
      <AppearanceGroup />
      <AboutGroup />
      <OthersGroup />
    </div>
  );
};

export const SettingDialog = ({
  close,
}: DialogComponentProps<GLOBAL_DIALOG_SCHEMA['setting']>) => {
  const t = useI18n();

  return (
    <ConfigModal
      title={t['com.affine.mobile.setting.header-title']()}
      open
      onOpenChange={() => close()}
      onBack={close}
    >
      <MobileSetting />
    </ConfigModal>
  );
};
