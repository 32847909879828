import "../../core/src/components/attachment-viewer/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/attachment-viewer/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71W24rbMBB9368QLYUEoiB7k+3WC4X2ob9RZGviqKtIRlI2zpb+e9HFjhzH2QulEDCSJnOOZs7MaPkzuzNq1RKCft8g1CjDLVeyQBoEtfwJHm4QYtw0gh4LtBHQug33xYxrqIJxpcR+J93JgTO7LVBGyCe33AKvt7Zb/7lZdnCZhxs5/rU3lm+OuFLSgrQFMg2tAJdgDwAy9bjOG/+PhjLGZe0gmhbdh82SVo+1VnvJCvRE9QxjutlwCfgpx4IeQeOTBW4031F9nPuLKWmx4c9QoCwC+K1DRF0R4vYqJZQeu7bQWmygUpJ1DkulGWhsVYNjaMhyHUmejow9CiiQUYKzs6MJqHALLg1n8D1Yhz95VE/koGlTIKnc122qJ9AboQ4F2nLGXDSTfOQ+H1dNMvQVndmP8lc7yC5yVPBaYm5hZwpUgbSgk4xhARt7Mu52dRSM304JLp+V2hVS2dnSbNVhPiQglYSB/e1lguMbxmidDkAI3hhuvJy33AL2IixQo301HJRmMbilBvqI3cYAe+Wxp4tkIjAj8Z+OfFxXZyFZB5hEsusLkl0HyQonnK548lUwvKbjtCx2VNdcOj1eyMzdiMalyrlIg7xMw4K2PPBIMD9fzu50yIMwI156nfz+7Dr3VxrhM+aSQVsgMtkWC5RN6UxDiM9Iq/dFCRulwSP32f/w4WFAhJZGib31RDx1TyLWCwltw1q1i4tQXmRAG2dnuL6cas197c1TGkmDvNqCEru086UoPUDqXzW04vbo+mG+HvbsKCOnjl4iySnf0RoKrySqca0p4yDt7JxeubdWSXdelqAxgw3dCztHWdMukNVUmoZqkNZtzBcjd1aF0C5G936T40EkvvibD0Zkckova/q1zWI0KfvJSMY1W3qsHW27qVRRUc0cJYTRinjirxmjvj/GQde6vHnAshtI6Zzrrj0YfhOD711DzzPYUuaKzklnFeXjF8FVkh1cClo94pyQBdJ1SWdkgeJvmZH5/MWJWb21BV2u5Om4dQHrSuD00sndpZLiv+2KJNb/bd/n2r7Xpgm+6xMcAJ3m96boX0//K2FvbzFveau9d+JMtDH2bx7IaU66V/K1YVEqux3yWFZKCNoYYC88gVho7735PBa9H36dVs7HH7z+3XSmntX/VE/KeGlAQGVjPIY+P+Zfvt3++PZw8+cvDLWt0+oMAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var error = '_16so4x04';
export var errorBtns = '_16so4x07';
export var errorMessage = '_16so4x06';
export var errorTitle = '_16so4x05';
export var pdfIndicator = '_16so4x0a';
export var pdfPage = '_16so4x0b';
export var pdfThumbnail = '_16so4x0e';
export var pdfThumbnails = '_16so4x0c';
export var pdfThumbnailsList = '_16so4x0d';
export var titlebar = '_16so4x01';
export var titlebarChild = '_16so4x02';
export var titlebarName = '_16so4x03';
export var viewer = '_16so4x08';
export var viewerContainer = '_16so4x00';
export var virtuoso = '_16so4x09';