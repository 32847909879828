import "../../component/src/ui/property/property.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/property/property.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81W7W7jKBT9n6dAkUZqpVI5aeqmVCPNG8wDVNWKmGubLQEWcD521XdfGfydOHU6s9L+aOoQ38vl3HPO5f6P52fzsP4rQv/MEGLcakGPBKUCDi8zhLZc4hx4ljuCHpbar2llueNKEmRAUMd34FcpY1xmBC31AUX64P9XEWU2vDdUE1R+vsw+Zvf1vq/M0CyjGwHf584UMH/zlSSFscoQlBm6+eT9V0YdxX6Zy6yXZcct33DB3ZGgnDMG8pNcZAOpMhBKUNKBdATN5y9dbDZCJe99IOjGKlE4D0S/coSc0gQ9Rt/KZwGpIygqHzfKMDDYUMYLS2qkNjR5z4wqJMOJEmWaHTU3GNM05RLwbokdHBzWgiaQK8HA3Po9DJU2VWZLwqOgDm5wrA93CD9G39p3qnpDS1F0v7Qoul/YO6Q0Tbg7tktlSLVYVVzzYBVK3XPm8s43IXCSU5lBnb5J+hnmNHVg/keQe6Q0NSDdOLjrHrg1Noso7FqBs/51cHK1A9OlZbNVVX/TpEU31cK/W8amQu1b9rfxkT5ReLXUSLn6fk5FZSu4dGAw7EA6S5BUEroVLM87ivcCxg0koY+JEsXWJ/yzsI6nR9yQwDpq3LjjDNpZETFV0mHL/4aBdML69rZ3wFgfmr9Vx6tsbrh8r2gjyvAaomWFemHBYAsCElcfvaTvuGQtJEoyao63HX4sYg9xB7RgZjm1eAuyOGuJFfATwgJ5fPDnxiLoEQzuvOeDf+7ACFoW/TFrjRZrAzsO+zfUVHBxr56i2rIfplHEqH25TAXPJOYOtpagBAIGCGXlWIn7MK7OJx7JcEK89qfzltHh2CIOSyOg8kTJXuvbGh99jf6smSklungZUaznT/sDCMG15d6h9zl3gK2mCZQ0DNN1igjaOuKvNyFo5b9S6VnddaVblzmwvpikKiks9rYlgmmqwpXpTl0qJqQzTKshNHXwdtIEbRigTElx/D5PqbDN3cRLY/SKc0bPV6Yj3N4E/d2h6vB77nIub3+b9uvKnnzGwAILIh2SYEpjp9tkO4nqfjeX0bhDpH19M4miMUd4QnaXBcX9DvE+VXZXJ72UQRu+Hcavrx2O/kzr/pmer3I5n2E5HPGrMP1O7vqDuUSv32o1EOg5b2s32PgNmrHYb/Yi/pLvngM+ueogU9jcY+BjYOCILV8CIAnaTpQQVFtgfauYqJg2HfNx3Ts/FSLc7oFawFxiVbjpCPawWA7YwS7V3rk+G+XKu/NzxCC75AQXhAdfEs6qXy+8Bvzf+snq2fBjC4xTpA2Xzr/RN7+TCIQ+Zh//AjgYJ6FJDwAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var hide = '_99r38q1';
export var propertyIconContainer = '_99r38q4';
export var propertyNameContainer = '_99r38q2';
export var propertyNameContent = '_99r38q5';
export var propertyNameInnerContainer = '_99r38q3';
export var propertyRoot = '_99r38q0';
export var propertyValueContainer = '_99r38q6';
export var section = '_99r38q8';
export var sectionCollapsedIcon = '_99r38qd';
export var sectionContent = '_99r38qe';
export var sectionHeader = '_99r38q9';
export var sectionHeaderIcon = '_99r38qb';
export var sectionHeaderName = '_99r38qc';
export var sectionHeaderTrigger = '_99r38qa';
export var tableButton = '_99r38q7';