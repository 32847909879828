import "../../component/src/components/auth-components/share.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/components/auth-components/share.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61X246jOBB9z1dYsxqp+8EISNLT45ZW8yerAhfEG2Mj2yTpWfW/rzAmARoSerVPCabKPufUzfw64nthoEJL/kpq6S5VuSX/bAiJv/sfQpwBZQttKkaMduDwKeZYPr9tCPnYELK/Y5i8jkyT+I7t9mVg+7GJejQxiaQutfcrtHLUit/IyAnME6VQFEIh9esHuvXe/uGMojw4Rl7iuF3LtdRm4pRJyI/eowJTCkVNcKkv7eIJjRM5SApSlIqRSnAu8W0M7U9Ss0IY66guqHuvcQ3M/SLMACTTzumKkV2HRLa+h2CZvnaLXNhawjsjhUS/4GFS4bCyjOSoHJoZsBK+hnW3iHUG1eC0xG9eA+dClVdC27gDX2srnNCKEYMSnDiNdU2is3AH3Th6EMrN7pTGnw6UkKH0xldtMqnz41L8HV4ctZhrxcG8U2/yvByFu0LZaqVO6QR2+jgIYe+bZpBZLRuH7eo0VbBwjKw5N0JjtPGnz4nj3141GTmewSihykXX8H7OeeedzoK7AyPJvsPcY+yf55KjJWo4GmqAi8aytuu0q/qEppD6zMhBcI7KW0J+LI1uFKezZd9tNIeOMSgc9qIoh8ox8u3b2wBzOsGcTjEPgzOIRugt/r/TdfjXR29Q/IxA4/Q8jz+Komjf/KZCcbwwki4pM2aVYaEN3qGVxmNa/fMjKWWDd3Kzox8i1VMNj1cKiVeknwZUG+FFaH2Dj29uSlTQnXCbUkm0t0SoQijh0Oc7jFve/nFxZWA7CqNyWR4uoTf4EG6nPWhPYLEqpFDHuaR7mW1vu3WN8se43fWj4O/GOlG0LS0E29aQI83QnbGrkUfD4nVlY1jXunynDWO0P2yieBLtv1xH15TqtBrXz4DMT0+mP2mbLgzQT6rdoM7K9SAZfpLIoEVfOhxvk2xuRqwRcXxJmc5bGHFM4vh0GLatOD6d/yfSa8ppBCw6G6jr0Fa/fG0BEgVs3r+CCw2kfsRTyYFETji54mLjzdZewz5dboBEtsluRz22/3pfyB4F9PtsPNtfyoXBvCujXMumUv8l0gMs+XBsb/tM/1UhF0BsbhAVAcXJ0yA6SZzu6suz95xk6T2U11boOdaX6yiaIeB3sQ6M6z8wlrLu3okjPx7Mr7oqrbD/JLlPeN8infDNw3aTqH1sPv4FqjK/f3cNAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var authCodeContainer = '_1pltxmg6';
export var authCodeErrorMessage = '_1pltxmg8';
export var authCodeWrapper = '_1pltxmg7';
export var authContent = '_1pltxmg5';
export var authInputWrapper = '_1pltxmg1';
export var authPageContainer = '_1pltxmga';
export var formHint = '_1pltxmg2';
export var hideInSmallScreen = '_1pltxmgd';
export var input = '_1pltxmgc';
export var loading = '_1pltxmg4';
export var modalHeaderWrapper = '_1pltxmg0';
export var resendButtonWrapper = '_1pltxmg9';
export var signInPageContainer = '_1pltxmgb';