import "../../core/src/components/mobile/config-modal/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/mobile/config-modal/styles.css.ts.vanilla.css\",\"source\":\"Ll82cmJ5N3owIHsKICBwYWRkaW5nOiAwOwogIG92ZXJmbG93LXk6IGF1dG87CiAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tYWZmaW5lLXYyLWxheWVyLWJhY2tncm91bmQtc2Vjb25kYXJ5KTsKfQouXzZyYnk3ejIgewogIGNvbG9yOiB2YXIoLS1hZmZpbmUtdjItdGV4dC1wcmltYXJ5KTsKICBkaXNwbGF5OiBpbmxpbmUtZmxleDsKICBhbGlnbi1pdGVtczogY2VudGVyOwogIGdhcDogOHB4Owp9Ci5fNnJieTd6MyB7CiAgZGlzcGxheTogZmxleDsKICBhbGlnbi1pdGVtczogY2VudGVyOwogIGdhcDogOHB4OwogIGNvbG9yOiB2YXIoLS1hZmZpbmUtdjItdGV4dC1wcmltYXJ5KTsKfQouXzZyYnk3ejQgewogIHBhZGRpbmc6IDI0cHggMTZweDsKICBkaXNwbGF5OiBmbGV4OwogIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47CiAgZ2FwOiAxNnB4Owp9Ci5fNnJieTd6NSB7CiAgZGlzcGxheTogZmxleDsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwogIGdhcDogMTZweDsKfQouXzZyYnk3ejYgewogIGNvbG9yOiB2YXIoLS1hZmZpbmUtdjItYnV0dG9uLXByaW1hcnkpOwp9Ci5fNnJieTd6NyB7CiAgd2lkdGg6IDEwMCU7Cn0KLl82cmJ5N3o4IHsKICBkaXNwbGF5OiBmbGV4OwogIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47CiAgZ2FwOiA0cHg7CiAgd2lkdGg6IDEwMCU7Cn0KLl82cmJ5N3o5IHsKICBjb2xvcjogdmFyKC0tYWZmaW5lLXYyLXRleHQtdGVydGlhcnkpOwogIHBhZGRpbmc6IDRweDsKfQouXzZyYnk3emEgewogIGJhY2tncm91bmQ6IHZhcigtLWFmZmluZS12Mi1sYXllci1iYWNrZ3JvdW5kLXByaW1hcnkpOwogIGJvcmRlci1yYWRpdXM6IDEycHg7CiAgcGFkZGluZzogNHB4OwogIGRpc3BsYXk6IGZsZXg7CiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjsKICBnYXA6IDhweDsKfQouXzZyYnk3emIgewogIGRpc3BsYXk6IGZsZXg7CiAgYWxpZ24taXRlbXM6IGNlbnRlcjsKICBnYXA6IDhweDsKICBwYWRkaW5nOiA4cHggNHB4Owp9\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var bottomDoneButton = '_6rby7z7';
export var doneButton = '_6rby7z6 xn1iiob';
export var group = '_6rby7z8';
export var groupContent = '_6rby7za';
export var groupTitle = '_6rby7z9 xn1iiom';
export var pageContent = '_6rby7z4';
export var pageModalContent = '_6rby7z0';
export var pageTitle = '_6rby7z2 xn1iiob';
export var popupContent = '_6rby7z5';
export var popupModalContent = '_6rby7z1';
export var popupTitle = '_6rby7z3 xn1iiob';
export var rowItem = '_6rby7zb';