import "../../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71W24rbMBB9z1eIwsIGquBcm2pp/6OUUhRpbE9Xlowkb7It++/Fkh3biZNtlqVv9nguZ84cjTz7uU4lVJuE/JkQItGVij8zkio4PEwIEUYZy8gTt/eU8jRFDdTDwdPSYsHtMw0O09o1B8xyz8h6VYbQOgV1uUX9yEhSW/Yofc7IPEnu6leuMNMUPRSOEecteJHHmtpz1MGGwtcmb7l26NFoRnZcPGbWVFrG4iSZLdxHYkou0D+HtzrEPIFNldkzkqOUoEPmyrq6HQkpr5R/mLxMZm3/LK8jAgunFU76D47HxnspvkvuORUKxSPfKfjibQU/Qsa2cGlQe7D9qHlwaMlbrSN5O2MlWGq5xMoxso3Ws/EMKBQQc/cbuAydpqgUyGmsVk+Ky5qtgX8Bumq+BMeCH2gzxeUmiaAK1K1tEW1dc4vQXGna2VlQ3OMTxFw2Q00VpJ4R2nTY8tBqxFReoQZGtNEhquRSos6asG0sF4mXlmdZj/gjCLaD1FiIkzDag/aMfPjwMIDGd86oyoci3pSMrCOCIL3U2ILFR8U9fLun6+QuMBJx9OW9GnbSvPYVHD9d0O0r4isVF5AbJXsSPJPLItZsUgd4r5MUtNGn6jiL03Tzi+l6RtTZaYmQtQM1W78xzxjIZQOyVeIY5ONBJV8JS9E6T0WOSkaRDoQ13wyFHEIUH42wEcF2GLEMPu1hWd12gH9VzmNab9dGrd2njJf18RgWW41v7wvZzzdzarSnDn/DidqCPafLwZ5bjxcLWSVaEFHlwqiq0GcwYnHPre8dZ0aShvLBjhFcift6FRBKNqvyMG3rhIESss/RA3UlF2FD7C0va3PlwFIHCoRvF0eHfhNVOHI7XCXBFdOjz77R3CZJrqDo4Qw3ZlcSlMLSoeu4caDS3h3Yof10Ee3Fm9mBMFoO7+arrR3cP/L69ja2t+nzdTWO94/C6H7XJzrv8Hx+PzwNezeNo6f6k3uvvVQHv0odbv7/cJ//4I2sjdpSq6OvkvPmrp7H3U0tnS3GAAG0vD5v8X5F4lqjYQ1vxv90X/4CVUEJr1ULAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionsCellWrapper = '_5fdeu6b';
export var dateCell = '_5fdeu6a';
export var dndCell = '_5fdeu62';
export var dragPageItemOverlay = '_5fdeu61';
export var iconCell = '_5fdeu68';
export var operationsCell = '_5fdeu6c';
export var root = '_5fdeu60';
export var selectionCell = '_5fdeu64';
export var tagsCell = '_5fdeu69';
export var titleCell = '_5fdeu65';
export var titleCellMain = '_5fdeu66';
export var titleCellPreview = '_5fdeu67';
export var titleIconsWrapper = '_5fdeu63';