import "../../component/src/ui/notification/desktop/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/notification/desktop/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA31U227bMAx9z1dwAQYsDyrsNO0yBf2SoRhoi7bZKZIhMXHSof8+WM7FSZM+mqTOTZQf/iwLxPfiGf5NAAofDAUV0PAmali2u1Wq7lRs0PhOwxbDD6WwqtjRoajyWT/ltxQq2880bAy51eRj8nBA/3kLnV1DgWV17nRspNGQH2lTMcrekoboLZtUxvJvHfzGGVV668NR04Eqm43O3hqYp4Fbnd7HWfMyaW7RGHa1hvy5V3Vu/0ptw7G1uNdQWUqi0XLtFAut41BUUTAkk2+bKFztVemdkBMNscWSVEHS0WVcmLAb4roRDfPFkMfXXCU5oTBGKRLKIdO7IK3tRVyBAKwx1OxUGCTk2aX5AuK2HsPnWfZ9NdJ8/L4V8+Ii5nIMk/VnUmp16Dcpv71YAEI7UecGWctt5Ni3uoaFVMpWg/NdwDaheieqO8hbZInJ9kt8HXMajPxOGvKnofR1GCY5uJq52hZKM7fSeEzr2PrIwt5pCGRReEuXq65BArrYYiA3ejG9QUdjouq3QUFVWY/yMpWwoenrsMgnBiyitxtJDOLbo1iAO9rru9qfZvCN160Pgr2q85FmHEniePoU7sWfZKivZ59vZT6IGWx1LI3i0rtXOFENnfQWXqbCYk+Oh6erLFWi4TFd78d/hTLd3vAEAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../../component/src/ui/notification/mobile/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/notification/mobile/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA31U22rjMBB9z1cMhYUEImO7oYlVFvY/lqWMrXEyVJaMpCRulv77Yjle101T8hB75syZ2xn/eqW32mFDHl6yY3nxT5jC3wVA7WwTHwAa7MSZVThI2Gza7nkB8L4ACPbWn6Xp6TwA3hfJyJhF4BVScxCVNYFM6IEH4v0hTMyldYqccKj46CXk+WBu0O3ZSEjbDvAYbG9rUSk2+z7rNRar172zR6NEZbV1Ek7olkK87ErES5muetBXnmw1pZaQtR14q1nNQfkV1Al/QGXPIwfWNRu6GsVApdi3Gt8k1JpiaXtsJeyGMlHz3ggO1HgJFZlArjfbE7la97wHVopMbwsOjefA1sjhubaugTTJ/PNswBKrwCeKc/6Pk+Ar1LRMk2K7muN/Kwwo0HCDgdTPh+CO9PAnhp/Yc8maw9tUyHehNWo/xn6hlAEYG5gEliY7D9Wx5EqUdGFyyyTfrpN8t06yx3VSFKtZqKhZa9FYRRJq687o1Lz/fJCsNUF4vpCE/Jpd95sZJZbeW/9mPp3Hz2zZdmCLpvMo2DS9yTCqVVMI5IRvsYoCFWmyeRxc5wMHih6SYOzZYTtLvonJWztuveaOVJSCba8taKrHbqaz+/HxmMb3GxnOpOcDujA/pKehyItgo6iTUBRFMb8sCW5f4jJdx1+SzUf3NFQ/8qWQX0/z+0qmI5ju5APr9h7r8FUQcTSfg4qPH504rD61hOzuFu6cIHVBTA7SmlvPc/1hzHXTY/8vFDuqhl1WVh+b/pz+AQ0k5QF2BQAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var detailActions = '_1ubzs6aa';
export var detailCard = '_1ubzs6a5';
export var detailContent = '_1ubzs6a7';
export var detailHeader = '_1ubzs6a6';
export var detailIcon = '_1ubzs6a8 _1ubzs6a2';
export var detailLabel = '_1ubzs6a9 _1ubzs6a3';
export var detailRoot = '_1ubzs6a4';
export var toastIcon = '_1ubzs6a2';
export var toastLabel = '_1ubzs6a3';
export var toastRoot = '_1ubzs6a1';