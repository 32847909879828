import "../../core/src/components/root-app-sidebar/index.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/root-app-sidebar/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51T227bMAx9z1dwBQY0DwqcLOk6BdiPDH2QLdrmKkuCTCfOhv77YNm52HW7bi++kEeHPOTRypl2vTVr+L0A0FR7o04ScoPtfgGgDBVWEGNVS8jQMoYu/LOpmfKTyJxltCyh9ipDkSIfEW2HKJSX8Ojb/eJlsepLbP6pxJUAwCutyRbxH5Ibyi+R8kiaS9klILJKWN9gth/A7OZb695CU8CMyVkJmTNNdZW3HcnbwXfQdJDW8f0PrVgJTQfSGJ6Wkb1SoSArUsfsKgkJfKLKu8DK8v6aZuenuUuFh//vEiB1QWMQQWlq6mnvXyPzZczrTX8ma0LtggTvqF/L9UCv0Af39Ppo/zh/3Jx6HGO3rxHfIiJ3lsURqShZwjaJSzNkUZRDbJP0DUZgTb9QwkGFeyFUnne4GG/rZRThTKdhlGdsWdSYOatV6GxsXIjgV8P9u9Nn/XsRpKKgN3vwgapxB4MPQi80We2wuqFLI915DOtkdre7PuoOGHLjjhJK0rpv1ruaepcENIrpgLEmWTFckM1DMlpIKmWKuQs46BimcHc3ZlNp7UzDkY1sjTxctVRlz0VwjdVibgapUdlzlO28yohPneJke1M/mwhOPs8IJltiIH6vYM+WLG+o9Yj6bKmLOxPfvnd3Ji7d9S79iCFnnTw3Hd8EFMeSGJdjaVPcxEQvfwBUaAP21AUAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var aiUsageBlock = 'olx14l7';
export var cloudUsageBar = 'olx14lb';
export var cloudUsageBarInner = 'olx14lc';
export var cloudUsageBlock = 'olx14l8';
export var freeTag = 'olx14ld';
export var operationMenu = 'olx14l5';
export var progressColorVar = 'var(--olx14l0)';
export var quickSearch = 'olx14l3';
export var quickSearchAndNewPage = 'olx14l2';
export var usageBlock = 'olx14l6';
export var usageLabel = 'olx14l9';
export var usageLabelTitle = 'olx14la';
export var workspaceAndUserWrapper = 'olx14l1';
export var workspaceWrapper = 'olx14l4';