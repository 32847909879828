import "../../core/src/components/affine/auth/style.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/auth/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61WTW+cMBC951dY6aU5eMWS/Ugc9dK/UVWVFw/gxNjUNptNq/73ygYWw8IuSDkh2Z43z29mnln9ei6S3/Acob93CBVUZ1xiq0qCHqPy9HL3727VnFgHJwiilVUvXcRBWasKgjYuBiELJ4up4JkkKAFpQYdI8UQuhBIllCboSPVXjGmacgnYYxlIlGRUf2B/5MEdTpW02PA/MAjw6yfjzwi3kAPPckvQerUNaTwup5HzLBcODKdKQ6ZVJRnWwD6FT4yoZzSWXXD5dr56GLJyO3VYpY2LKxWvBV+EtPEYpTLccuXqezBKVBYcjK7pRi8DuXC867fINlS0bYlhH+3mXRKhA03eaokJsppKU1IN0votpRlo3IAMNhfXIY7rslcGNDYgILEESSUhpL33tK9im+JhcRPfVrzVMQrZPHk27QX2+/oCjJtS0A+CUgF+wX0x4xqSOkeiRFVIt/NaGctTx0RakLYbU4T83GJuoTDh8jVvePZ0LtJfSZLRkqCnmvZoMW4r3aWfnhuve6l5MWId703GXdST9uDB3jmzOUGb7RxDS3oh0XTIvAZqlBaQOpPYTXvSQVRweZ9N/z7Mkxtns6RiE20xZ9p69LbRhZHsGpWHj8mgy2C8y+YwOHdXFHTXgNFMAy0rDfg95xYGLoVHS6Rpu1PPO2WMy4ygqH0rGy/TlPHKnF/QpYUIVPrBqKWYG3wAweEI+tu91RXc//TyXfL98rjfrLfrECMdV9oP7boZ0Il+GCod1rkNHdS5Xu6yZ7XVCjgRtA4xo0Cvc3B5QkYJzvq6H2Ms6AdozKXhDL63Qe7T84580juO8cC2Z77yHTa/id1Y0+z/h8u+7bK93szmHtjFl3ibC9uFiIlBnXyNxlv6P7NnMIecCgAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var accessMessage = '_9mcqe9d';
export var authMessage = '_9mcqe92';
export var authModalContent = '_9mcqe90';
export var captchaWrapper = '_9mcqe91';
export var errorMessage = '_9mcqe93';
export var forgetPasswordButton = '_9mcqe97';
export var forgetPasswordButtonRow = '_9mcqe94';
export var linkButton = '_9mcqe96';
export var resendCountdown = '_9mcqe9b';
export var resendCountdownInButton = '_9mcqe9c';
export var resendWrapper = '_9mcqe98';
export var sendMagicLinkButtonRow = '_9mcqe95';
export var sentMessage = '_9mcqe9a';
export var sentRow = '_9mcqe99';
export var skipDivider = '_9mcqe9f';
export var skipDividerLine = '_9mcqe9g';
export var skipDividerText = '_9mcqe9h';
export var skipLink = '_9mcqe9j';
export var skipLinkIcon = '_9mcqe9k';
export var skipSection = '_9mcqe9l';
export var skipText = '_9mcqe9i';
export var userPlanButton = '_9mcqe9e';