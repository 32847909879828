import "../../component/src/ui/modal/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61W227bOBB991fMBihgA6FBK46TyChQ9Bfal2KRh5E0srmhSIGkJTtF/r0gKTuyYqdOu0+x5nbOGQ6H+fJEu9JgRRae7mr+YObwcwRQGl2FHwC6xly4XQp8OQJ4GQE4PXTNoutl9GVY7vb9cgDOoLKlNlUaf0p09GPMkk8TsDlKGvPpw2LyG+hzZfi+yGxyluEiVHxb+I/4nRL61wzvhj08XWrG+ae3jToHexbt/jI0filUj9bLaBoxspBRayuc0CqFUmyp8DFCWXJd7zPMn1ZGb1TBci21SaFBM2YMy1IoYj13pQuUMSggPTOhCtoOEjprjA5xqESFkcF++Ge3vLJQatOiKeyyR3nqo5nSigL5Xq63HUVWOhMyhv1GRJMwiTsyx2p89oHmoW5+SdMKYWuJuxRKSdugUYqVYsJRZVPISTky3vzfxjpR7liulSPl+q7LuvdK698CHXasH2Nv+pieCCMVmNZYFEKtWKad01UKpJqxxZIYGkIWdHS+a0h4vT2BU26kZDY3ROrzlTMbuoqYXekUOPwjqlobh8r10y8+vy6yxIK++av5XdfDjP1ui9OSbzKRs4yeBZkxn86Ta+DXwKe399cwOx40VgopfQ8pPRqyqC2WvXmE80ymjWOYO9FEFY2gloXLFoaCKaxoT+/hrSYrRUFfQ4NPS7oDPk1uLBBa+nviPbgP8EZfN02HIVoW407Y5DT5xf93Hu/B4xn4+4/2rmtWEcqxrok8hWXvc3b8mcTPVhRuvb+gXV4QtiaxWrtjT5RcCcVOeZPoxe3Bm6PM/dYumjUwuEnCNYwhHe5rRAtsf1EBMr1lVjyHW5hpU/i1psMSKrVy3kWDpRLsGVqaHKLajsWch30mfdie2Wy6WPavukeGZF4HjNfFaEiin7SPPSK6ISNxx2pU1H9Mgqo1Frod5Na69jmds4sNqg0WYmNTmCWRmt44r+N105xfZedmYsZ50w4Gw9vWg+k42HrHdcjtn/IhcMCZ9yaTBi8OZlbLjQuNdbpOIekEmljTn8cfvCBl/I/jvRlZs8XbEVmcHJH5bZRqVkIdHpp4EgfEVUBstSlYZgifUgh/mLeE+7UWjpitMacUakOsNViHk2zIlFK3wXCctT/VIO/zVYnS0tXj4O0W/pFl1JBydjgOx4nHkzDMxI3Ty9HLLyhNihO+CwAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var closeButton = 'k7p09re';
export var heightVar = 'var(--k7p09r1)';
export var minHeightVar = 'var(--k7p09r2)';
export var modalContent = 'k7p09rd';
export var modalContentWrapper = 'k7p09rc';
export var modalDescription = 'k7p09rg';
export var modalHeader = 'k7p09rf';
export var modalOverlay = 'k7p09rb';
export var modalVTScope = 'k7p09r3';
export var widthVar = 'var(--k7p09r0)';